.bg-white {
    background-color: white;
}

#logo {
    width: 30px;
    height: 30px;
    border-radius: 4px;
}

.navbar-brand {
    padding: 14px 20px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: bolder;
    background-color: white;
}

.navbar-nav {
    width: 100%;
    background-color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.nav-item {
    padding: 6px 14px;
    text-align: center;
}

.nav-link {
    padding-bottom: 10px; 
    color: black !important;
    transition: 0.2s;
}
.nav-link:hover {
    transform: scale(105%);
    transition: 0.2s;
}

.nav-link-login {
    color: white !important;
    background-color: var(--background-red);
    border-radius: 0.3vw;
    padding-left: 1vw !important;
    padding-right: 1vw !important;
    transition: 0.2s;
}

.v-line {
    background-color: gray;
    width: 1px;
    height: 20px;
}

.navbar-collapse.collapse.in {
    display: block !important;
}

@media (max-width: 576px) {
    .nav-item {
        width: 100%;
        text-align: left;
    }

    .v-line {
        display: none;
    }

    .nav-link-login {
        color: black !important;
        background-color: white;
    }

    .ml-auto {
        padding: 0 !important;
        margin: 0 !important;
    }
}