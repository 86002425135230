:root {
    --background-red: rgb(229, 21, 21);
}

body {
    background-color: rgb(229, 21, 21) !important;
    height: 100% !important;
    width: 100% !important;
    overflow: hidden;
}

p {
    font-family: 'Poppins', sans-serif;
    color: white;
    font-size: x-large;
}

h1 {
    font-family: 'Poppins', sans-serif;
    color: white;
}

h2 {
    font-family: 'Poppins', sans-serif;
    color: white;
    font-size: xx-large !important;
}

.footer {
    background-color: white;
    width: 100%;
    height: 3%;
    position: absolute;
    text-align: center;
    bottom: 0;
    vertical-align: middle;
    justify-content:center;
}

.footer-text {
    font-family: 'Poppins', sans-serif;
    color: black;
    font-size: medium;
    font-weight: bold;
}

.trackella-info-img {
    height: 50%;
    width: 50%;
    margin: 0;
}

@media (max-width: 800px) {
    .footer-text {
        font-size: 1.5vw;
    }

    .trackella-info-img {
        height: 80%;
        width: 80%;
    }
}



/*Animations*/
@keyframes fade-in-slide-up-anim {
    from {
        opacity: 0;
        transform: translateY(2vw);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
.fade-in-slide-up {
    opacity: 0;
    animation-name: fade-in-slide-up-anim;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

@keyframes fade-in-slide-up-delayed {
    from {
        opacity: 0;
        transform: translateY(2vw);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
.fade-in-slide-up-delayed {
    opacity: 0;
    animation-name: fade-in-slide-up-anim;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
}

